<script setup lang="ts">
import { useStoryBlokSeo } from '~/composables/useStoryBlokSeo'
import type { SbSeoStoryblok } from '~/types/storyblok'

const props = defineProps<{ blok: SbSeoStoryblok }>()

useStoryBlokSeo(props.blok)
</script>

<template>
  <div />
</template>
