import type { SbSeoStoryblok, SbSocialSeoStoryblok } from '~/types/storyblok'

export function useStoryBlokSeo(SbSeo: SbSeoStoryblok) {
  const { t } = useI18n()

  if (!SbSeo) {
    return
  }

  const metaSocial = SbSeo.socialSeos?.reduce((result, item) => {
    const keyValue = item.socialNetwork
    if (keyValue) {
      result[keyValue] = item
    }
    return result
  }, {} as Record<SbSocialSeoStoryblok['socialNetwork'], SbSocialSeoStoryblok>)

  const metaFb = metaSocial?.Facebook
  const metaTwitter = metaSocial?.Twitter
  const metaGoogle = metaSocial?.Google

  const defaultTitle = t('common.seo.title')
  const defaultDescription = t('common.seo.description')
  const defaultFilename = '/img/thumbnail.jpg'
  const defaultImageAlt = t('common.seo.imageAltHomePage')

  // Schema.org markup for Google+
  function getGoogleMeta(seo: SbSocialSeoStoryblok) {
    return [
      { itemprop: 'name', content: seo.title || defaultTitle },
      { itemprop: 'description', content: seo.description || defaultDescription },
      { itemprop: 'image', content: seo.image?.filename || defaultFilename },
      { property: 'image:alt', content: seo.image?.alt || defaultImageAlt },
    ]
  }

  // Open Graph / Facebook
  function getFacebookMeta(seo: SbSocialSeoStoryblok) {
    return [
      { property: 'og:title', content: seo.title || defaultTitle },
      { property: 'og:description', content: seo.description || defaultDescription },
      { property: 'og:image', content: seo.image?.filename || defaultFilename },
      { property: 'og:image:alt', content: seo.image?.alt || defaultImageAlt },
    ]
  }

  // Twitter
  function getTwitterMeta(seo: SbSocialSeoStoryblok) {
    return [
      { property: 'twitter:card', content: 'summary' },
      { property: 'twitter:title', content: seo.title || defaultTitle },
      { property: 'twitter:description', content: seo.description || defaultDescription },
      { property: 'twitter:image', content: seo.image?.filename || defaultFilename },
      { property: 'twitter:image:alt', content: seo.image?.alt || defaultImageAlt },
    ]
  }

  useHead({
    title: SbSeo.title,
    meta: [
      { name: 'description', content: SbSeo.description },
      ...(SbSeo.keywords ? [{ name: 'keywords', content: SbSeo.keywords }] : []),
      ...(metaGoogle ? getGoogleMeta(metaGoogle) : []),
      ...(metaFb ? getFacebookMeta(metaFb) : []),
      ...(metaTwitter ? getTwitterMeta(metaTwitter) : []),
    ],
  })
}
